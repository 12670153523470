import { Link } from "gatsby"
import React from "react"

const Hero = ({ title, sub }) => {
  return (
    <div className="border-b-4 h-screen bg-green-500 md:text-center border-white text-gray-50 py-16">
      <div className="py-8 px-5">
        <h1 className="text-5xl lg:text-8xl font-semibold font-serif px-2 pb-2 leading-snug border-b-8 inline border-purple-300">
          {title}
        </h1>
        <p className="text-2xl lg:text-4xl mx-0 lg:mx-10 py-10 md:py-20 font-semibold">
          {sub}
        </p>
      </div>
      <div className="mx-2 md:pt-10">
        <Link
          to="/peliharaan/"
          className="text-xl md:text-2xl border-b-2 pb-2 font-serif font-semibold text-gray-500 mx-2"
        >
          Peliharaan
        </Link>
        <Link
          to="/resep/"
          className="text-xl md:text-2xl border-b-2 pb-2 font-serif font-semibold text-gray-500 mx-2"
        >
          Resep
        </Link>
      </div>
    </div>
  )
}

export default Hero
