import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
const Posts = ({ title, link, diskripsi, time, kategori, gambar, tags }) => {
  return (
    <div className="md:px-4 px-2 w-full md:w-1/2 lg:w-1/3 pb-4 pt-6 md:pt-10 ">
      <div className="border-b-2  border-red-100 md:border-transparent">
        <Link to={`/${kategori}`}>
          <button className="px-2 font-semibold bg-blue-100 rounded border-2 border-blue-200 text-blue-400 ml-2 capitalize">
            {kategori}
          </button>
        </Link>
        <Link to={link}>
          <h2 className="my-4 capitalize font-serif font-bold text-lg">
            {title}
          </h2>
        </Link>
        <div>
          <Link to={link}>
            <GatsbyImage
              className="border-2 border-red-300 rounded-xl overflow-hidden"
              image={gambar}
              alt={title}
            />
          </Link>
        </div>
        <p className="text-sm font-medium text-gray-500 text-right pt-1.5 mr-4">
          {time} min
        </p>

        <div className="flex-row flex-wrap pb-4 pt-0.5 flex">
          {tags.map((tag, i) => [
            <p
              className="bg-red-50 text-xs px-2 capitalize rounded font-medium text-red-500 m-1 border-2 border-red-300 "
              key={i}
            >
              {tag}
            </p>,
          ])}
        </div>
        <p className="whitespace-pre-wrap text-sm mb-3 text-gray-500">
          {diskripsi}
        </p>
      </div>
    </div>
  )
}

export default Posts
